.main {
    font-family: 'Helvetica Neue', Helvetica, sans-serif;
    font-size: 20px;
    font-weight: normal;
    max-width: 450px;
    margin: 50px auto;
    display: flex;
    flex-direction: column;
    background-color: white;
}

p {
    max-width: 255px;
    word-wrap: break-word;
    margin-bottom: 12px;
    line-height: 24px;
    position: relative;
    padding: 10px 20px;
    border-radius: 25px;

    &:before,
    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        height: 25px;
    }
}

.send {
    color: white;
    background: #0b93f6;
    align-self: flex-end;

    &:before {
        right: -7px;
        width: 20px;
        background-color: #0b93f6;
        border-bottom-left-radius: 16px 14px;
    }

    &:after {
        right: -26px;
        width: 26px;
        background-color: white;
        border-bottom-left-radius: 10px;
    }
}

.receive {
    background: #e5e5ea;
    color: black;
    align-self: flex-start;

    &:before {
        left: -7px;
        width: 20px;
        background-color: #e5e5ea;
        border-bottom-right-radius: 16px 14px;
    }

    &:after {
        left: -26px;
        width: 26px;
        background-color: white;
        border-bottom-right-radius: 10px;
    }
}
